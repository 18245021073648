<template>
  <div class="home" v-loading="loading">
    <div class="plr20 pt10">
      <el-alert title="后台设置的页面和小程序有偏差，请以小程序为准" type="warning">
      </el-alert>
    </div>
    <!-- 按钮集合 -->
    <section class="buttons">
      <div>
        <el-button type="primary" icon="el-icon-receiving" @click="showModelList"></el-button>
        <el-button type="primary" @click="showPreserveDefaults('model')"><i class="el-icon-plus"></i> 保存成模板
        </el-button>
      </div>
      <div>
        <el-button @click="delModel()" type="info"><i class="el-icon-s-tools"></i> 重置为默认</el-button>
        <el-button @click="reloads()" type="danger"><i class="el-icon-delete-solid el-icon--left"></i>清空</el-button>
        <!-- <el-button @click="catJson">查看JSON </el-button> -->
        <!-- <el-button @click="$refs.file.click()">导入JSON </el-button> -->
        <!-- <el-button @click="exportJSON">导出JSON </el-button> -->
        <!-- <input type="file" ref="file" id="file" accept=".json" @change="importJSON" style="display: none" /> -->
        <!-- <el-button @click="Preservation"
          ><i class="el-icon-s-claim el-icon--left"></i>保存</el-button
        > -->
        <el-button type="primary" @click="submitData()"><i class="el-icon-s-claim el-icon--left"></i>保存 </el-button>

      </div>
    </section>

    <!-- 装修操作 -->
    <section class="operation">
      <!-- 组件 -->
      <div>
        <association-home-sliderassembly v-if="titleType === 0" :pointer="pointer" />
        <association-my-sliderassembly v-else-if="titleType === 1" :pointer="pointer" />
        <association-mall-sliderassembly v-else-if="titleType === 2" :pointer="pointer" />
      </div>

      <!-- 手机 -->
      <div class="phone">
        <section class="phoneAll" ref="imageTofile" id="imageTofile">
          <img src="./images/phoneTop.png" alt="" class="statusBar" />

          <!-- 头部导航 -->
          <div>
            <association-home-header-top v-if="titleType === 0" :pageSetup="homePageSetup" @click.native="headTop" />
            <association-my-header-top v-else-if="titleType === 1" :pageSetup="myPageSetup" @click.native="headTop" />
            <association-mall-header-top v-else-if="titleType === 2" :pageSetup="mallPageSetup"
              @click.native="headTop" />
          </div>

          <div class="phone-container" v-if="titleType === 0" :style="{
    backgroundColor: homePageSetup.bgColor,
    backgroundImage: 'url(' + homePageSetup.bgImg + ')',
  }">
            <!-- 主体内容 -->
            <section class="phone-section" @drop="drop($event)" @dragover="allowDrop($event)"
              @dragleave="dragleaves($event)">
              <div :class="pointer.show ? 'pointer-events' : ''" ref="pageComponents">
                <!-- 动态组件 -->
                <component :is="item.component" v-for="(item, index) in pageComponents" :key="index"
                  :datas="item.setStyle" :style="{
    border: item.active && deleShow ? '2px solid #51CDCB' : '',
  }" :class="{ 'mr10': hideMlr.indexOf(item.component) == -1, 'ml10': hideMlr.indexOf(item.component) == -1 }"
                  @click.native="activeComponent(item, index)" class="componentsClass" :data-type="item.type">
                  <div v-if="deleShow" class="deles" slot="deles" @click.stop="deleteObj(index)">
                    <!-- 删除组件 -->
                    <span class="iconfont icon-sanjiaoxingzuo"></span>
                    {{ item.text }}
                    <i class="el-icon-delete-solid" />
                  </div>
                </component>
              </div>
            </section>
          </div>
          <div class="phone-container" v-else-if="titleType === 1" :style="{
    backgroundColor: myPageSetup.bgColor,
    backgroundImage: 'url(' + myPageSetup.bgImg + ')',
  }">
            <!-- 主体内容 -->
            <section class="phone-section" @drop="drop($event)" @dragover="allowDrop($event)"
              @dragleave="dragleaves($event)">
              <div :class="pointer.show ? 'pointer-events' : ''">
                <!-- 动态组件 -->
                <component :is="item.component" v-for="(item, index) in pageComponents" :key="index"
                  :datas="item.setStyle" :style="{
    border: item.active && deleShow ? '2px solid #51CDCB' : '',
  }" @click.native="activeComponent(item, index)"
                  :class="{ 'mr10': hideMlr.indexOf(item.component) == -1, 'ml10': hideMlr.indexOf(item.component) == -1 }"
                  class="componentsClass" :data-type="item.type">
                  <div v-if="deleShow" class="deles" slot="deles" @click.stop="deleteObj(index)">
                    <!-- 删除组件 -->
                    <span class="iconfont icon-sanjiaoxingzuo"></span>
                    {{ item.text }}
                    <i class="el-icon-delete-solid" />
                  </div>
                </component>
              </div>
            </section>
          </div>
          <div class="phone-container" v-else-if="titleType === 2" :style="{
    backgroundColor: mallPageSetup.bgColor,
    backgroundImage: 'url(' + mallPageSetup.bgImg + ')',
  }">
            <!-- 主体内容 -->
            <section class="phone-section" @drop="drop($event)" @dragover="allowDrop($event)"
              @dragleave="dragleaves($event)">
              <div :class="pointer.show ? 'pointer-events' : ''">
                <!-- 动态组件 -->
                <component :is="item.component" v-for="(item, index) in pageComponents" :key="index"
                  :datas="item.setStyle" :style="{
    border: item.active && deleShow ? '2px solid #51CDCB' : '',
  }" @click.native="activeComponent(item, index)"
                  :class="{ 'mr10': hideMlr.indexOf(item.component) == -1, 'ml10': hideMlr.indexOf(item.component) == -1 }"
                  class="componentsClass" :data-type="item.type">
                  <div v-if="deleShow" class="deles" slot="deles" @click.stop="deleteObj(index)">
                    <!-- 删除组件 -->
                    <span class="iconfont icon-sanjiaoxingzuo"></span>
                    {{ item.text }}
                    <i class="el-icon-delete-solid" />
                  </div>
                </component>
              </div>
            </section>
          </div>


          <!-- 手机高度 -->
          <div class="phoneSize">iPhone 8手机高度</div>

          <!-- 底部 -->
          <association-home-phone-bottom v-if="titleType === 0" :pageSetup="homePageSetup" @click.native="headTop" />
        </section>
        <!-- 底部 -->
      </div>

      <!-- 页面设置tab -->
      <div class="decorateTab">
        <span v-if="showWebType" :class="titleType == 0 ? 'active' : ''" @click="modelSwitchFun(0)">
          <i class="el-icon-s-home" />
          首 页
        </span>
        <span v-if="showWebType" :class="titleType === 1 ? 'active' : ''" @click="modelSwitchFun(1)">
          <i class="el-icon-user" />
          我 的
        </span>
        <span v-if="showWebType" :class="titleType === 2 ? 'active' : ''" @click="modelSwitchFun(2)">
          <i class="el-icon-goods" />
          商 城
        </span>
        <hr class="mb10" style="border: 0.5px solid #dcdfe6;">
        <span
          :class="rightcom === 'associationHomeDecorate' || rightcom === 'associationMyDecorate' || rightcom === 'associationMallDecorate' ? 'active' : ''"
          @click="setRightcom()">
          <i class="iconfont icon-wangye" />
          页面设置
        </span>
        <span :class="rightcom === 'componenmanagement' ? 'active' : ''" @click="rightcom = 'componenmanagement'">
          <i class="iconfont icon-zujian" />
          组件管理
        </span>
        <span class="active"
          v-show="rightcom != 'componenmanagement' && rightcom != 'associationHomeDecorate' && rightcom != 'associationMyDecorate' && rightcom != 'associationMallDecorate'">
          <i class="iconfont icon-zujian" />
          组件设置
        </span>


      </div>

      <!-- 右侧工具栏 -->
      <div class="decorateAll">
        <!-- 页面设置 -->
        <transition name="decorateAnima">
          <!-- 动态组件 -->
          <component :is="rightcom" :datas="currentproperties" :titleType="titleType"
            @componenmanagement="componenmanagement" />
        </transition>
      </div>
    </section>
    <!-- 展示 -->
    <!-- <realTimeView :datas="realTimeView" :val="{
      id,
      name: pageSetup.name,
      templateJson: JSON.stringify(pageSetup),
      component: JSON.stringify(pageComponents),
    }" /> -->
    <add-model ref="addModel" :btnName="defaultModelBtn" :imgUrl="dialogImageUrl" :title="defaultModelTitle"
      @submitData="modelSubmit" @automatically="toImage" @uploadImg="uploadModelImg"></add-model>
    <app-model-list ref="modelList" :accountType="accountType" type="association" :listId="associationId"
      @useDragModel="useDragModel" :titleType="titleType"></app-model-list>
  </div>
</template>

<script>
import utils from './utils/index' // 方法类
import componentProperties from './utils/associationComponentProperties' // 组件数据
import html2canvas from 'html2canvas' // 生成图片
// import FileSaver from 'file-saver' // 导出JSON
//
import {
  getAssociationMessageList,
  getGoodsMemberLinkList,
  getAssociationEmployeeList,
  selectGoodsTypeInfoPC,
  getGoodsInfoPCShop,
  activityEventList,
  getGoodsListByLink,


  getDragPageAndModule,
  setDragPageAndModule,
  getAssociationTotalData,
  delDragPageAndModule
} from "@/api/appModel";
// import { getAssociationMessageList, getGoodsListByLink, getContentTypeList, getByDictType, getDragPageAndModule, setDragPageAndModule, getAssociationTotalData } from "@/api/appModel";

import addModel from '@/components/common/addModel' // 添加模板
import appModelList from '@/components/common/appModelList' // 显示模板
import httpUtil from "@/utils/httpUtil";
import { converse } from "@/utils";

export default {
  name: 'home',
  inject: ['reload'],
  components: { addModel, appModelList },
  data() {
    return {
      // realTimeView: {
      //   show: false, // 是否显示预览
      // },
      //设置为默认操作配置
      defaultFormData: {},//端的类型 
      dialogImageUrl: '', // 上传的图片
      defaultModelTitle: "",//标题
      defaultModelBtn: "",//按钮名称
      automatically: false,

      titleType: 0,//页面类型 0：首页 1：我的 2：商城
      id: null, //当前页面
      deleShow: true, //删除标签显示
      index: '', //当前选中的index
      rightcom: 'associationHomeDecorate', //右侧组件切换
      currentproperties: {}, //当前属性
      userInfo: this.$store.state.loginRoot.userInfo,
      homePageSetup: {
        // 页面设置属性
        name: '自动获取社团名称', //页面名称
        details: '', //页面描述
        isAddress: true, // 是否显示选择地区
        bgColor: 'rgba(249, 249, 249, 10)', //背景颜色
        bgImg: '', // 背景图片
        titleHeight: 45, // 高度
        titleColor: "#fff",
        titleBgColor: "#3064ba",
        titleBgImg: "",//标题背景图
        childTitleBgColor: "#5785e7",
        childTitleColor: false,
        navigationData: {
          activeTitleColor: "#3064ba",
          titleColor: "#9d9ea0",
          data: [
            {
              activeIcon: this.$store.state.ossUrl + "yimai_photos/iconFont/tabBarImage/home-active.png",
              icon: this.$store.state.ossUrl + "yimai_photos/iconFont/tabBarImage/home.png",
              title: "首页",
              type: 1,//首页1 人脉圈2 会员企业3 视频4 商城5 发现6 商机7  我的8
              isShow: true
            },
            {
              activeIcon: this.$store.state.ossUrl + "yimai_photos/iconFont/tabBarImage/renmai-active.png",
              icon: this.$store.state.ossUrl + "yimai_photos/iconFont/tabBarImage/renmai.png",
              title: "人脉圈",
              type: 2,//首页1 人脉圈2 会员企业3 视频4 商城5 发现6 商机7  我的8
              isShow: true
            },
            {
              activeIcon: this.$store.state.ossUrl + "yimai_photos/iconFont/tabBarImage/yuanqi-active.png",
              icon: this.$store.state.ossUrl + "yimai_photos/iconFont/tabBarImage/yuanqi.png",
              title: "会员企业",
              type: 3,//首页1 人脉圈2 会员企业3 视频4 商城5 发现6 商机7  我的8
              isShow: true
            },
            {
              activeIcon: this.$store.state.ossUrl + "yimai_photos/iconFont/tabBarImage/video_select.png",
              icon: this.$store.state.ossUrl + "yimai_photos/iconFont/tabBarImage/video.png",
              title: "视频",
              type: 4,//首页1 人脉圈2 会员企业3 视频4 商城5 发现6 商机7  我的8
              isShow: false
            },
            {
              activeIcon: this.$store.state.ossUrl + "yimai_photos/iconFont/tabBarImage/shop_select.png",
              icon: this.$store.state.ossUrl + "yimai_photos/iconFont/tabBarImage/shop.png",
              title: "商城",
              type: 5,//首页1 人脉圈2 会员企业3 视频4 商城5 发现6 商机7  我的8
              isShow: false
            },
            {
              activeIcon: this.$store.state.ossUrl + "yimai_photos/iconFont/tabBarImage/faxian-active.png",
              icon: this.$store.state.ossUrl + "yimai_photos/iconFont/tabBarImage/faxian.png",
              title: "发现",
              type: 6,//首页1 人脉圈2 会员企业3 视频4 商城5 发现6 商机7  我的8
              isShow: true
            },
            {
              activeIcon: this.$store.state.ossUrl + "yimai_photos/iconFont/tabBarImage/shangji_select.png",
              icon: this.$store.state.ossUrl + "yimai_photos/iconFont/tabBarImage/shangji.png",
              title: "商机",
              type: 7,//首页1 人脉圈2 会员企业3 视频4 商城5 发现6 商机7  我的8
              isShow: false
            },
            {
              activeIcon: this.$store.state.ossUrl + "yimai_photos/iconFont/tabBarImage/center-active.png",
              icon: this.$store.state.ossUrl + "yimai_photos/iconFont/tabBarImage/center.png",
              title: "我的",
              type: 8,//首页1 人脉圈2 会员企业3 视频4 商城5 发现6 商机7  我的8
              isShow: true
            }
          ]
        },
      },
      myPageSetup: {
        name: "会员中心",
        details: '', //页面描述
        bgColor: 'rgba(249, 249, 249, 10)', //背景颜色
        bgImg: '', // 背景图片
        titleHeight: 45, // 高度
        titleColor: "#fff",
        titleBgColor: "#3064ba",
        titleBgImg: this.$store.state.ossUrl + "yimai_photos/iconFont/yqhshetuan/center01.png",//标题背景图
      },
      mallPageSetup: {
        name: '区域商城', //页面名称
        details: '', //页面描述
        bgColor: 'rgba(249, 249, 249, 10)', //背景颜色
        bgImg: '', // 背景图片
        titleHeight: 45, // 高度
        titleColor: "#000",
        titleBgColor: "#fff",//标题背景图
        titleBgImg: "",
        showButton: true,
      },

      pageComponents: [], //页面组件
      offsetY: 0, //记录上一次距离父元素高度
      pointer: { show: false }, //穿透
      onlyOne: ['tabBar', 'suspension'], // 只能存在一个的组件(组件的type)
      associationId: 0,
      loading: false,
      loadingTime: 0,//加载时间
      loadingTimeFun: null,
      showWebType: false,
      accountType: 9,//1:名片端，2：crm端，8：园区端，9：社团端
      hideMlr: ['placementarea', 'pictureads', 'tabBar'],
      pid: 0,
    }
  },
  computed: {
    watchAssociation() {
      return this.$store.state.checkedAssociation
    }
  },
  watch: {
    /* 监听右侧属性设置切换 */
    rightcom(newval) {
      if (newval === 'associationHomeDecorate' || newval === 'associationMyDecorate' || newval === 'associationMallDecorate') {
        utils.forEach(this.pageComponents, (res) => {
          /* 修改选中 */
          if (res.active === true) res.active = false
        })
        if (this.titleType === 0) {
          this.currentproperties = this.homePageSetup
        } else if (this.titleType === 1) {
          this.currentproperties = this.myPageSetup
        } else if (this.titleType === 2) {
          this.currentproperties = this.mallPageSetup
        }
        return
      }
      if (newval === 'componenmanagement') {
        /* 替换 */
        utils.forEach(this.pageComponents, (res) => {
          /* 修改选中 */
          if (res.active === true) res.active = false
        })
        this.currentproperties = this.pageComponents
      }
    },
    watchAssociation(row) {
      this.associationId = row.associationId;
      this.homePageSetup.name = row.associationName;
      this.initConfig();
    },
  },
  mounted() {
    let associationData = JSON.parse(sessionStorage.getItem('checkedAssociation'))
    this.associationId = associationData.associationId;
    this.initConfig();
  },

  methods: {
    //删除拖拽数据
    delModel() {
      this.$confirm('重置后您添加或者修改的内容将会失效, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        if (this.pid) {
          delDragPageAndModule(this.pid).then(res => {
            this.getWebData();
          })
        } else {
          this.getWebData();
        }
      })
    },
    showModelList() {
      this.$refs.modelList.showModel();
    },
    /**@method 显示上传模板 */
    showPreserveDefaults() {
      if (this.titleType == 0) {
        this.defaultModelTitle = "设置为首页模板"
      } else if (this.titleType == 1) {
        this.defaultModelTitle = "设置为个人中心模板"
      } else if (this.titleType == 2) {
        this.defaultModelTitle = "设置为商城模板"
      }
      this.defaultModelBtn = "保存为社团模板"
      this.dialogImageUrl = "";
      this.automatically = false;
      this.$refs.addModel.showModel();
    },
    uploadModelImg() {
      this.automatically = false;
    },
    /**@method 提交模板 */
    modelSubmit(formData) {
      this.defaultFormData = formData;
      this.submitData(true);
    },
    /**@method 初始化配置 */
    initConfig() {
      // if (this.titleType === 0) {
      //   this.currentproperties = this.homePageSetup
      // } else if (this.titleType === 1) {
      //   this.currentproperties = this.myPageSetup
      // } else if (this.titleType === 2) {
      //   this.currentproperties = this.mallPageSetup
      // }
      this.loading = true;
      this.loadingTime = new Date().getTime();//加载时间
      clearTimeout(this.loadingTimeFun);//停掉之前的懒加载计时器
      this.loadingTimeFun = null;
      this.initData().then(() => {
        this.showWebType = true;
        this.getWebData()
      })
    },
    /**@method 模块切换 */
    modelSwitchFun(type) {
      this.titleType = type;
      this.loading = true;
      this.loadingTime = new Date().getTime();
      clearTimeout(this.loadingTimeFun);
      this.loadingTimeFun = null;
      this.getWebData()
    },
    setRightcom() {
      if (this.titleType === 0) {
        this.rightcom = 'associationHomeDecorate'
        this.currentproperties = this.homePageSetup
      } else if (this.titleType === 1) {
        this.rightcom = 'associationMyDecorate'
        this.currentproperties = this.myPageSetup
      } else if (this.titleType === 2) {
        this.rightcom = 'associationMallDecorate'
        this.currentproperties = this.mallPageSetup
      }
    },
    /**@method 加载默认数据 */
    initData() {
      return Promise.all([
        new Promise(open => {
          getAssociationTotalData({
            associationId: this.associationId,
            accountType: this.accountType,
            showIcon: false,
            showAll: 0
          }).then(res => {
            //清空所有自动数据
            this.$store.commit("setAssociationDrag", {
              key: "productData",
              list: []
            })
            this.$store.commit("setAssociationDrag", {
              key: "articleData",
              list: { all: [] }
            })
            this.$store.commit("setAssociationDrag", {
              key: "articleAllData",
              list: []
            })
            this.$store.commit("setAssociationDrag", {
              key: "activityData",
              list: []
            })
            this.$store.commit("setAssociationDrag", {
              key: "productTypeData",
              list: []
            })
            this.$store.commit("setAssociationDrag", {
              key: "productGroupData",
              list: []
            })
            this.$store.commit("setAssociationDrag", {
              key: "goodsLinkData",
              list: []
            })
            this.$store.commit("setAssociationDrag", {
              key: "memberStyleData",
              list: []
            })
            this.$store.commit("setAssociationDrag", {
              key: "dynamicType",
              list: []
            });
            this.$store.commit("setAssociationDrag", {
              key: "articleType",
              list: []
            });
            this.$store.commit("setAssociationDrag", {
              key: "photoUrl",
              list: []
            })
            this.$store.commit("setAssociationDrag", {
              key: "myWebUrl",
              list: []
            })
            this.$store.commit("setAssociationDrag", {
              key: "webUrl",
              list: []
            })
            if (res.data) {
              //获取商品
              if (res.data.goodsModelList) {
                let productData = [];
                for (let row of res.data.goodsModelList) {
                  productData.push({
                    coverUrl: row.goodPhoto.split(",")[0],
                    // type: val.goodstypeId,
                    id: row.goodsId,
                    name: row.goodsName,
                    price: row.price / 100
                  });
                }
                this.$store.commit("setAssociationDrag", {
                  key: "productData",
                  list: productData
                })
              }
              //获取有图片的动态文章
              if (res.data.onlyPhotosMessageList) {
                let articleData = [];
                for (let row of res.data.onlyPhotosMessageList) {
                  articleData.push({
                    id: row.messageId,
                    type: row.typeName,
                    img: row.photos,
                    releaseTime: row.createTime.slice(0, 10),
                    text: row.title,
                    originAuthor: row.originAuthor
                  });
                }
                this.$store.commit("setAssociationDragObjData", {
                  parentKey: "articleData",
                  key: "all",
                  list: articleData
                })
              }
              //获取全部动态文章
              if (res.data.associationMessageList) {
                let articleAllData = [];
                for (let row of res.data.associationMessageList) {
                  articleAllData.push({
                    id: row.messageId,
                    type: row.typeName,
                    img: row.photos,
                    releaseTime: row.createTime.slice(0, 10),
                    title: row.title,
                    originAuthor: row.originAuthor
                  });
                }
                this.$store.commit("setAssociationDrag", {
                  key: "articleAllData",
                  list: articleAllData
                })
              }
              //获取活动数据
              if (res.data.activityEventList) {
                let activityData = [];
                for (let row of res.data.activityEventList) {
                  activityData.push({
                    id: row.aeId,
                    hostUnit: row.hostUnit,
                    img: row.photos,
                    address: row.activityPlace,
                    releaseTime: row.createdTime.slice(0, 10),
                    text: row.description,
                  });
                }
                this.$store.commit("setAssociationDrag", {
                  key: "activityData",
                  list: activityData
                })
              }
              //商品分类
              if (res.data.goodsTypeInfoList) {
                let produtType = [];
                let productGroupData = [];
                for (let row of res.data.goodsTypeInfoList) {
                  if (row.typeIcon) {
                    produtType.push({
                      id: row.goodstypeId,
                      src: row.typeIcon,
                      text: row.name.slice(0, 4),
                      dictLabel: row.dictLabel,
                      dictValue: row.dictValue,
                    });
                  }
                  let productData = [];
                  if (row.goodsModelList) {
                    for (let productRow of row.goodsModelList) {
                      productData.push({
                        coverUrl: productRow.goodPhoto.split(",")[0],
                        // type: val.goodstypeId,
                        id: productRow.goodsId,
                        name: productRow.goodsName,
                        price: productRow.price / 100
                      })
                    }
                  }
                  productGroupData.push({
                    name: row.name.slice(0, 4),
                    typdId: row.goodstypeId,
                    productData: productData
                  });
                }
                this.$store.commit("setAssociationDrag", {
                  key: "productTypeData",
                  list: produtType
                })
                this.$store.commit("setAssociationDrag", {
                  key: "productGroupData",
                  list: productGroupData
                })
              }
              //套餐数据
              if (res.data.goodMemberLinkList) {
                let goodsLinkData = [];
                for (let row of res.data.goodMemberLinkList) {
                  goodsLinkData.push({
                    id: row.levelId,
                    src: row.coverImg,
                    price: (row.price / 100).toFixed(2),
                    title: row.goodsName,
                  });
                }
                this.$store.commit("setAssociationDrag", {
                  key: "goodsLinkData",
                  list: goodsLinkData
                })
              }
              //获取成员数据
              if (res.data.associationEmployeeList) {
                let memberStyleData = [];
                for (let row of res.data.associationEmployeeList) {
                  memberStyleData.push({
                    id: row.companyId,
                    companyName: row.companyName,
                    levelName: row.levelName,
                    img: row.logo,
                    name: row.name
                  });
                }
                this.$store.commit("setAssociationDrag", {
                  key: "memberStyleData",
                  list: memberStyleData
                })
              }
              //获取社团动态类型
              if (res.data.contentTypeList) {
                let typeData = [];
                let articleType = [];
                for (let row of res.data.contentTypeList) {
                  typeData.push({
                    title: row.typeName,
                    url: "id=" + row.typeId + "&title=" + row.typeName
                  });
                }
                articleType = res.data.contentTypeList;
                this.$store.commit("setAssociationDrag", {
                  key: "dynamicType",
                  list: typeData
                });
                this.$store.commit("setAssociationDrag", {
                  key: "articleType",
                  list: articleType
                });
              }
              //获取字典数据
              if (res.data.dictTypeList) {
                for (let row of res.data.dictTypeList) {
                  if (row.dictType == 'photo_url') {
                    this.$store.commit("setAssociationDrag", {//获取链接类型
                      key: "photoUrl",
                      list: row.sysDictDataList ? row.sysDictDataList : []
                    })
                  } else if (row.dictType == 'association_my_web_url') {//获取我的网页类型
                    this.$store.commit("setAssociationDrag", {
                      key: "myWebUrl",
                      list: row.sysDictDataList ? row.sysDictDataList : []
                    })
                  } else if (row.dictType == 'association_web_url') {//获取网页链接
                    this.$store.commit("setAssociationDrag", {
                      key: "webUrl",
                      list: row.sysDictDataList ? row.sysDictDataList : []
                    })
                  }
                }
              }
            }
            open();
          }).catch(err => {
           //-console.log(err);
            open();
          })
        }),

      ])
    },
    /** @method 保存提交数据 */
    submitData(isModel) {
      /** 清除没必要的参数值 */
      let str = JSON.stringify(this.pageComponents)
      let pageComponents = JSON.parse(str);
      let pageSetup = null;
      let productJudgment = null;
      if (this.titleType === 0) {
        pageSetup = this.homePageSetup;
      } else if (this.titleType === 1) {
        pageSetup = this.myPageSetup;
      } else if (this.titleType === 2) {
        pageSetup = this.mallPageSetup
      }
      for (let index in pageComponents) {
        let row = pageComponents[index];
        //清理商品多余的数据
        if (row.component === 'listswitching') {
          if (row.isPage) {
            productJudgment = {
              index: index,
              isCheck: false,
              msg: "开启分页"
            }
          }
          //分组格式里的商品数据
          if (row.setStyle.addMethod === 'manual') {//只有为手动添加状态时才保存id
            let goodsIds = [];
            for (let product of row.setStyle.manualData) {
              goodsIds.push(product.id);
            }
            row.setStyle.goodsIds = goodsIds;
          } else {
            delete row.setStyle.goodsIds;
          }
          //普通格式商品数据
          delete row.setStyle.autoData;
          delete row.setStyle.manualData;
          delete row.setStyle.commoditylisttypetab;
        }
        //清除多余的活动数据
        if (row.component === "activity") {
          if (row.setStyle.addMethod === 'manual') {
            let activityIds = [];
            for (let activity of row.setStyle.manualData) {
              activityIds.push(activity.id);
            }
            row.setStyle.activityIds = activityIds;
          } else {
            delete row.setStyle.activityIds;
          }
          delete row.setStyle.autoData;
          delete row.setStyle.manualData;
        }
        //清除多余的文章数据
        if (row.component === "newsarticle") {
          if (row.setStyle.addMethod === 'manual') {
            let articleIds = [];
            for (let article of row.setStyle.manualData) {
              articleIds.push(article.id);
            }
            row.setStyle.articleIds = articleIds;
          } else {
            delete row.setStyle.articleIds
          }
          delete row.setStyle.autoData;
          delete row.setStyle.manualData;
        }
        //清除多余的套餐数据
        if (row.component === "magiccube") {
          if (row.setStyle.addMethod === 'manual') {
            let levelIds = [];
            for (let goods of row.setStyle.imageList) {
              levelIds.push(goods.id);
            }
            row.setStyle.levelIds = levelIds.join(",");
          } else {
            delete row.setStyle.levelIds;
          }
          delete row.setStyle.imageList;
          delete row.setStyle.autoData;
        }
        //清除多余的套餐数据
        if (row.component === "memberStyle") {
          if (row.setStyle.addMethod === 'manual') {
            let employeeIds = [];
            for (let employee of row.setStyle.manualData) {
              employeeIds.push(employee.id);
            }
            row.setStyle.employeeIds = employeeIds.join(",");
          } else {
            delete row.setStyle.employeeIds;
          }
          delete row.setStyle.manualData;
          delete row.setStyle.autoData;
        }
        //清除多余的套餐数据
        if (row.component === "notice") {
          if (row.setStyle.addMethod === 'manual') {
            let articleIds = [];
            for (let article of row.setStyle.activity) {
              articleIds.push(article.id);
            }
            row.setStyle.articleIds = articleIds;
          } else {
            delete row.setStyle.articleIds;
          }
          delete row.setStyle.activity;
          delete row.setStyle.autoData;
        }
        //清除多余的商品分类数据
        if (row.component === "productType") {
          if (row.setStyle.addMethod === 'manual') {
            let goodstypeIds = [];
            for (let productType of row.setStyle.imageList) {
              goodstypeIds.push(productType.id);
            }
            row.setStyle.goodstypeIds = goodstypeIds;
          } else {
            delete row.setStyle.goodstypeIds;
          }
          delete row.setStyle.imageList;
          delete row.setStyle.autoData;
        }
      }
      pageSetup.dragModuleRequest = pageComponents;
      pageSetup.titleType = this.titleType;
      pageSetup.accountType = this.accountType;
      let params = {
        ...pageSetup
      };
      if (isModel) {//判断是否为上传默认模板
        if (!this.defaultFormData.coverImg) {
          this.$message({
            message: "请上传效果图",
            type: "info"
          })
          return;
        }
        params = {
          isModel: 1,
          ...pageSetup,
          ...this.defaultFormData
        };

      }
      this.$refs.addModel.loading = true;
      if (isModel && this.automatically) {//上传的生成后的图片 并 提交模板
        let file = converse(this.dialogImageUrl, "modelImg")
        httpUtil.uploadFile(this.$store.state.uploadingUrl, file, (res) => {
          if (res.data) {
            params.coverImg = this.$store.state.ossUrl + res.data;
            this.dialogImageUrl = this.$store.state.ossUrl + res.data;
            this.automatically = false;
            setDragPageAndModule('association', this.associationId, params).then(res => {
              this.resultFun(res, isModel)
            }).catch(err => {
              if (err.code === 201) {
                this.$message.error(err.message);
              }
            })
          } else {
            this.$message.error("保存失败")
          }
        });
      } else {//提交模板
        setDragPageAndModule('association', this.associationId, params).then(res => {
          this.resultFun(res, isModel)
        }).catch(err => {
          if (err.code === 201) {
            this.$message.error(err.message);
          }
        })
      }
    },
    resultFun(res, isModel) {
      this.$refs.addModel.loading = false;
      if (res.code === 200) {
        this.$message({
          type: "success",
          message: "保存成功"
        })
        this.getWebData();
        if (isModel) {
          this.$refs.addModel.hideModel();
        }
      } else {
        this.$message.error(res.message);
      }
    },
    /**@method 使用模板 */
    useDragModel(model) {
      this.setDragData(model)
      this.$refs.modelList.hideModel();
    },
    setDragData(data) {
     //-console.log("setDragData");
      if (data) {
        let {
          bgColor,
          details,
          isAddress,
          name,
          titleBgColor,
          childTitleBgColor,
          childTitleColor,
          titleColor,
          titleHeight,
          titleBgImg,
          dragModuleResponseList,
          showButton,
          navigationData,
          pid
        } = data;
        if (this.titleType === 0) {
          this.homePageSetup = {
            bgColor,
            details,
            isAddress,
            name,
            childTitleBgColor,
            childTitleColor,
            titleBgColor,
            titleColor,
            titleHeight,
            titleBgImg,
            navigationData: this.homePageSetup.navigationData
          };
        } else if (this.titleType === 1) {
          this.myPageSetup = {
            bgColor,
            details,
            name,
            titleBgColor,
            titleColor,
            titleHeight,
            titleBgImg,
          };
        } else if (this.titleType === 2) {
          this.mallPageSetup = {
            bgColor,
            details,
            name,
            titleBgColor,
            titleColor,
            titleHeight,
            titleBgImg,
            showButton,
          };
        }
        this.pid = pid;
        let pageComponents = [];
        let promises = [];
        for (let i in dragModuleResponseList) {
          let row = dragModuleResponseList[i]
          let { component,
            setStyle,
            style,
            text,
            type
          } = row;
          pageComponents.push({
            component: component,
            text: text,
            type: type,
            active: false,
            style: style,
            setStyle: JSON.parse(setStyle)
          })
          let componentDetails = JSON.parse(setStyle);//内容数据
          if (row.component === 'listswitching') {//获取商品的数据
            let prodType = pageComponents[i].setStyle.prodType;
            if (prodType) {
              promises.push(new Promise(open => {
                let productData = [];
                this.$store.commit("setAssociationDragObjData", {
                  parentKey: "typeProductData",
                  key: prodType,
                  list: productData
                });
                getGoodsListByLink({
                  pageSize: 10,
                  accountType: 9,
                  associationId: this.$store.state.checkedAssociation.associationId,
                  goodstypeId: prodType,
                  isPutOn: true
                }).then(res => {
                  if (res.data) {
                    for (let row of res.data.pageInfo.list) {
                      productData.push({
                        coverUrl: row.goodPhoto.split(",")[0],
                        // type: val.goodstypeId,
                        id: row.goodsId,
                        name: row.goodsName,
                        price: row.price / 100
                      });
                    }
                  }
                  this.$store.commit("setAssociationDragObjData", {
                    parentKey: "typeProductData",
                    key: prodType,
                    list: productData
                  });
                  productData = this.$store.state.associationDrag.typeProductData[prodType];
                  pageComponents[i].setStyle.autoData = productData.slice(0, componentDetails.dataNumber)
                  open();
                }).catch(err => {
                 //-console.log(err);
                  this.$store.commit("setAssociationDragObjData", {
                    parentKey: "typeProductData",
                    key: prodType,
                    list: productData
                  });
                  open();
                })
              }))
            } else {
              pageComponents[i].setStyle.autoData = this.$store.state.associationDrag.productData.slice(0, componentDetails.dataNumber);
            }
            //自动获取数据
            //没分组格式
            pageComponents[i].setStyle.manualData = [];
            //分组格式
            for (let key in componentDetails.commoditylisttypetab) {
              let row = pageComponents[i].setStyle.commoditylisttypetab[key]
              row.autoData = this.$store.state.associationDrag.productData.slice(0, row.dataNumber);
            }
            //获取手动添加数据
            let commoditylisttypetab = [];
            //分组格式
            for (let row of this.$store.state.associationDrag.productGroupData) {
              commoditylisttypetab.push({
                typeId: row.typeId,
                text: row.name,
                autoData: row.productData
              });
            }
            pageComponents[i].setStyle.commoditylisttypetab = commoditylisttypetab;
            // Promise.all(childPromises).then(() => {
            //   pageComponents[i].setStyle.commoditylisttypetab = commoditylisttypetab;
            // });
            //没分组格式
            if (componentDetails.goodsIds && componentDetails.goodsIds.length > 0) {
              promises.push(new Promise(open => {
                getGoodsInfoPCShop({ goodsIds: componentDetails.goodsIds, pageSize: 50, pageNum: 1 }).then(res => {
                  if (res.data) {
                    pageComponents[i].setStyle.manualData = [];
                    delete pageComponents[i].setStyle.goodsIds;
                    for (let row of res.data.pageInfo.list) {
                      pageComponents[i].setStyle.manualData.push({
                        coverUrl: row.coverImg,
                        id: row.goodsId,
                        name: row.goodsName,
                        price: row.price / 100
                      })
                    }
                  }
                  open();
                })
              }));
            }
            // componentDetails.commoditylisttypetab


          } else if (row.component === "activity") {//获取活动数据
            pageComponents[i].setStyle.autoData = this.$store.state.associationDrag.activityData.slice(0, componentDetails.dataNumber);
            pageComponents[i].setStyle.manualData = [];
            if (componentDetails.activityIds && componentDetails.activityIds.length > 0) {
              promises.push(new Promise(open => {
                activityEventList({ isPutOn: true, aeIds: componentDetails.activityIds, associationId: this.associationId, pageSize: 50, pageNum: 1, isPutOn: true }).then(res => {
                  if (res.data) {
                    for (let row of res.data.pageInfo.list) {
                      pageComponents[i].setStyle.manualData.push({
                        id: row.aeId,
                        hostUnit: row.hostUnit,
                        img: row.photos,
                        address: row.activityPlace,
                        releaseTime: row.createdTime.slice(0, 10),
                        text: row.description,
                      })
                    }

                  }
                  open();
                })
              }))
            }
          } else if (row.component === "newsarticle") {//获取社团动态数据
            //判断是否有文章类型id 使用id获取社团动态 保存到vuex
           //-console.log(pageComponents[i].setStyle.articleType);
            if (pageComponents[i].setStyle.articleType) {
              //社团动态没有该类型数据时调用接口获取对应类型的动态数据
              let promisesChild = [];
              if (!this.$store.state.associationDrag.articleData[pageComponents[i].setStyle.articleType]) {
                //社团动态没有该类型数据先赋值，用于处理调用接口时的延迟问题
                this.$store.commit("setAssociationDragObjData", {
                  parentKey: "articleData",
                  key: pageComponents[i].setStyle.articleType,
                  list: true
                })
                promisesChild.push(new Promise(open => {
                  getAssociationMessageList({
                    pageSize: 50,
                    pageNum: 1,
                    associationId: this.associationId,
                    isPage: 1,
                    typeId: pageComponents[i].setStyle.articleType,
                    onlyPhotos: true
                  }).then(res => {
                    if (res.data) {
                      let articleData = [];
                      for (let row of res.data.pageInfo.list) {
                        articleData.push({
                          id: row.messageId,
                          type: row.typeName,
                          img: row.photos,
                          releaseTime: row.createTime.slice(0, 10),
                          text: row.title,
                          originAuthor: row.originAuthor
                        });
                      }
                      this.$store.commit("setAssociationDragObjData", {
                        parentKey: "articleData",
                        key: pageComponents[i].setStyle.articleType,
                        list: articleData
                      })
                      pageComponents[i].setStyle.autoData = articleData.slice(0, componentDetails.dataNumber);
                    } else {
                      this.$store.commit("setAssociationDragObjData", {
                        parentKey: "articleData",
                        key: pageComponents[i].setStyle.articleType,
                        list: []
                      })
                    }
                    open();
                  }).catch(err => {
                   //-console.log(err);
                    this.$store.commit("setAssociationDragObjData", {
                      parentKey: "articleData",
                      key: pageComponents[i].setStyle.articleType,
                      list: []
                    })
                    open();
                  })
                }))
              } else {
                promisesChild.push(new Promise(open => {
                  let time = setInterval(() => {
                    let article = this.$store.state.associationDrag.articleData[pageComponents[i].setStyle.articleType];
                    if (typeof article === "object") {
                      pageComponents[i].setStyle.autoData = article.slice(0, componentDetails.dataNumber);
                      clearInterval(time);
                      open();
                    }
                  }, 500)
                }))
              }
              promises.push(new Promise(open => {
                Promise.all(promisesChild).then(() => {
                  open();
                })
              }))
            } else {
              let article = this.$store.state.associationDrag.articleData.all;
              pageComponents[i].setStyle.autoData = article.slice(0, componentDetails.dataNumber);
            }
            pageComponents[i].setStyle.manualData = [];
            if (componentDetails.articleIds && componentDetails.articleIds.length > 0) {
              promises.push(new Promise(open => {
                getAssociationMessageList({ messageIds: componentDetails.articleIds, onlyPhotos: true }).then(res => {
                  if (res.data) {
                    for (let row of res.data) {
                      pageComponents[i].setStyle.manualData.push({
                        id: row.messageId,
                        type: row.typeName,
                        img: row.photos,
                        releaseTime: row.createTime.slice(0, 10),
                        text: row.title,
                        originAuthor: row.originAuthor
                      })
                    }

                  }
                  open();
                })
              }))
            }
          } else if (row.component === "magiccube") {//获取套餐数据
            pageComponents[i].setStyle.autoData = this.$store.state.associationDrag.goodsLinkData.slice(0, componentDetails.dataNumber);
            pageComponents[i].setStyle.imageList = [];
            if (componentDetails.levelIds && componentDetails.levelIds.length > 0) {
              promises.push(new Promise(open => {
                getGoodsMemberLinkList({
                  accountType: this.accountType,
                  levelIds: componentDetails.levelIds,
                  pageSize: 50,
                  pageNum: 1,
                }).then(res => {
                  if (res.data) {
                    for (let row of res.data.pageInfo.list) {
                      pageComponents[i].setStyle.imageList.push({
                        id: row.levelId,
                        src: row.coverImg,
                        price: (row.price / 100).toFixed(2),
                        title: row.goodsName,
                      })
                    }

                  }
                  open();
                })
              }))
            }
          } else if (row.component === "memberStyle") {//获取成员数据数据
            pageComponents[i].setStyle.autoData = this.$store.state.associationDrag.memberStyleData.slice(0, componentDetails.dataNumber);
            pageComponents[i].setStyle.manualData = [];
            if (componentDetails.employeeIds && componentDetails.employeeIds.length > 0) {
              promises.push(new Promise(open => {
                getAssociationEmployeeList({ employeeIds: componentDetails.employeeIds, pageSize: 50, pageNum: 1, memberStatus: 0, accountType: this.accountType }).then(res => {
                  if (res.data) {
                    for (let row of res.data.pageInfo.list) {
                      pageComponents[i].setStyle.manualData.push({
                        id: row.employeeId,
                        companyName: row.companyName,
                        levelName: row.levelName,
                        img: row.logo,
                        name: row.name
                      })
                    }

                  }
                  open();
                })
              }))
            }
          } else if (row.component === "notice") {//获取成员数据数据
            let article = this.$store.state.associationDrag.articleAllData;
            pageComponents[i].setStyle.autoData = article.slice(0, componentDetails.dataNumber);
            pageComponents[i].setStyle.activity = [];
            if (componentDetails.articleIds && componentDetails.articleIds.length > 0) {
              promises.push(new Promise(open => {
                getAssociationMessageList({ messageIds: componentDetails.articleIds }).then(res => {
                  if (res.data) {
                    for (let row of res.data) {
                      pageComponents[i].setStyle.activity.push({
                        id: row.messageId,
                        title: row.title,
                      })
                    }

                  }
                  open();
                })
              }))
            }
          } else if (row.component === "productType") {//获取套餐数据
            pageComponents[i].setStyle.autoData = this.$store.state.associationDrag.productTypeData.slice(0, componentDetails.dataNumber);
            pageComponents[i].setStyle.imageList = [];
            if (componentDetails.goodstypeIds && componentDetails.goodstypeIds.length > 0) {
              promises.push(new Promise(open => {
                selectGoodsTypeInfoPC({
                  goodstypeIds: componentDetails.goodstypeIds,
                  isPage: false,
                }).then(res => {
                  if (res.data) {
                    for (let row of res.data) {
                      pageComponents[i].setStyle.imageList.push({
                        id: row.goodstypeId,
                        src: row.typeIcon,
                        text: row.name,
                        dictLabel: row.dictLabel
                      })
                    }

                  }
                  open();
                })
              }))
            }
          }
        }
        if (promises.length > 0) {
          Promise.all(promises).then(() => {
           //-console.log("拖拽成功");
            if (pageComponents.length > 0) {
              this.activeComponent(pageComponents[0], 0);
            }

            this.pageComponents = pageComponents;
          }).catch(err => {
            console.error(err);
          })
        } else {
         //-console.log(pageComponents);
          if (pageComponents.length > 0) {
            this.activeComponent(pageComponents[0], 0);
          }

          this.pageComponents = pageComponents;
        }
      }
      //处理加载太快闪屏问题
      let interval = new Date().getTime() - this.loadingTime;//获取访问接口时间间隔
      if (interval > 800) {//大于1秒的直接加载完成
        this.loading = false;
      } else {
        this.loadingTimeFun = setTimeout(() => {//小于1秒就等到1秒才加载完成
          this.loading = false;
          clearTimeout(this.loadingTimeFun);
          this.loadingTimeFun = null;
        }, (800 - interval))
      }
    },
    /** @method 初始化数据 */
    getWebData() {
      this.setRightcom();
      this.pageComponents = [];
      getDragPageAndModule('association', this.associationId, { titleType: this.titleType, accountType: this.accountType }).then(res => {
        this.setDragData(res.data)
      }).catch(err => {
       //-console.log(err);
        this.loading = false;
      });
    },
    /** @method 查看JSON */
    // catJson() {
    //   /** 清除没必要的参数值 */
    //   let str = JSON.stringify(this.pageComponents)
    //   let pageComponents = JSON.parse(str);
    //   for (let row of pageComponents) {
    //     //清理商品多余的数据
    //     if (row.component === 'listswitching') {
    //       //分组格式里的商品数据
    //       for (let typeData of row.setStyle.commoditylisttypetab) {
    //         let ids = [];
    //         for (let productData of typeData.imageList) {
    //           ids.push(productData.id)
    //         }
    //         delete typeData.imageList;
    //         typeData.goodsIds = ids;
    //       }
    //       //普通格式商品数据
    //       let goodsIds = [];
    //       for (let productData of row.setStyle.imageList) {
    //         goodsIds.push(productData.id);
    //       }
    //       delete row.setStyle.imageList;
    //       row.setStyle.goodsIds = goodsIds;
    //     }
    //     //清除多余的活动数据
    //     if (row.component === "activity") {
    //       let aeIds = [];
    //       for (let activity of row.setStyle.activity) {
    //         aeIds.push(activity.id);
    //       }
    //       delete row.setStyle.activity;
    //       row.setStyle.aeIds = aeIds;
    //     }
    //     //清除多余的文章数据
    //     if (row.component === "newsarticle") {
    //       let dynamicIds = [];
    //       for (let article of row.setStyle.article) {
    //         dynamicIds.push(article.id);
    //       }
    //       delete row.setStyle.article;
    //       row.setStyle.dynamicIds = dynamicIds;
    //     }
    //   }
    //   this.$alert(
    //     `{
    //       <br/>
    //       "id": ${this.id},
    //       <br/>
    //       "name": "${this.pageSetup.name}",
    //       <br/>
    //       "templateJson": '${JSON.stringify(this.pageSetup)}',
    //       <br/>
    //       "component": '${JSON.stringify(pageComponents)}',
    //       <br/>
    //     }`,
    //     '查看JSON',
    //     {
    //       confirmButtonText: '确定',
    //       customClass: 'JSONView',
    //       dangerouslyUseHTMLString: true,
    //       callback: () => { },
    //     }
    //   )
    // },
    /** @method 导出json */
    // exportJSON() {
    //   // 将json转换成字符串
    //   const data = JSON.stringify({
    //     id: this.id,
    //     name: this.pageSetup.name,
    //     templateJson: JSON.stringify(this.pageSetup),
    //     component: JSON.stringify(this.pageComponents),
    //   })
    //   const blob = new Blob([data], { type: '' })
    //   FileSaver.saveAs(blob, `${this.pageSetup.name}.json`)
    // },
    /** @method 导入json */
    // importJSON() {
    //   const file = document.getElementById('file').files[0]
    //   const reader = new FileReader()
    //   reader.readAsText(file)
    //   let _this = this
    //   reader.onload = function () {
    //     // this.result为读取到的json字符串，需转成json对象
    //     let ImportJSON = JSON.parse(this.result)
    //     // 检测是否导入成功
    //    //-console.log(ImportJSON, '-----------------导入成功')
    //     // 导入JSON数据
    //     _this.id = ImportJSON.id
    //     _this.pageSetup = JSON.parse(ImportJSON.templateJson)
    //     _this.pageComponents = JSON.parse(ImportJSON.component)
    //   }
    // },

    /**
     * @method 页面截图
     * @param {Function} callBack 回调函数
     */
    toImage() {
      this.deleShow = false;
      /* 加载 */
      this.$nextTick().then(() => {
        const imageTofiles = document.querySelector('#imageTofile')
        /* 截图 */
        html2canvas(this.$refs.imageTofile, {
          backgroundColor: 'transparent',
          height: imageTofiles.scrollHeight,
          width: imageTofiles.scrollWidth,
          useCORS: true,
          allowTaint: true
        }).then((canvas) => {
          /* 显示border和删除图标 */
          let url = canvas.toDataURL('image/png')
          this.dialogImageUrl = url;
          this.automatically = true;
          this.deleShow = true;
          this.$refs.addModel.loading = false;
        }).catch(err => {
         //-console.log(err);
          this.$message.error("生成效果图失败，组件中有视频组件，无法生成效果图")
          this.$refs.addModel.loading = false;
        })
      })



    },
    /**
     * @method 当将元素或文本选择拖动到有效放置目标（每几百毫秒）上时，会触发此事件
     * @param {Object} event event对象
     */
    allowDrop(event) {
      //阻止浏览器的默认事件
      event.preventDefault()

      /* 获取鼠标高度 */
      let eventoffset = event.offsetY

      /* 如果没有移动不触发事件减少损耗 */
      if (this.offsetY === eventoffset) return
      else this.offsetY = eventoffset

      /* 获取组件 */
      const childrenObject = event.target.children[0]

      // 一个以上的组件计算
      if (this.pageComponents.length) {
        /* 如果只有一个组件并且第一个是提示组件直接返回 */
        if (
          this.pageComponents.length === 1 &&
          this.pageComponents[0].type === 0
        )
          return

        /* 如果鼠标的高度小于第一个的一半直接放到第一个 */
        if (eventoffset < childrenObject.children[0].clientHeight / 2) {
          /* 如果第一个是提示组件直接返回 */
          if (this.pageComponents[0].type === 0) return

          /* 删除提示组件 */
          this.pageComponents = this.pageComponents.filter(
            (res) => res.component !== 'placementarea'
          )

          /* 最后面添加提示组件 */
          this.pageComponents.unshift({
            component: 'placementarea',
            type: 0,
          })

          return
        }

        /* 记录距离父元素高度 */
        const childOff = childrenObject.offsetTop

        /* 鼠标在所有组件下面 */
        if (
          eventoffset > childrenObject.clientHeight ||
          childrenObject.lastChild.offsetTop -
          childOff +
          childrenObject.lastChild.clientHeight / 2 <
          eventoffset
        ) {
          /* 最后一个组件是提示组件返回 */
          if (this.pageComponents[this.pageComponents.length - 1].type === 0)
            return

          /* 清除提示组件 */
          this.pageComponents = this.pageComponents.filter(
            (res) => res.component !== 'placementarea'
          )

          /* 最后一个不是提示组件添加 */
          this.pageComponents.push({
            component: 'placementarea',
            type: 0,
          })

          return
        }

        const childrens = childrenObject.children

        /* 在两个组件中间，插入 */
        for (let i = 0, l = childrens.length; i < l; i++) {
          const childoffset = childrens[i].offsetTop - childOff

          if (childoffset + childrens[i].clientHeight / 2 > event.offsetY) {
            /* 如果是提示组件直接返回 */
            if (this.pageComponents[i].type === 0) break

            if (this.pageComponents[i - 1].type === 0) break

            /* 清除提示组件 */
            this.pageComponents = this.pageComponents.filter(
              (res) => res.component !== 'placementarea'
            )

            this.pageComponents.splice(i, 0, {
              component: 'placementarea',
              type: 0,
            })
            break
          } else if (childoffset + childrens[i].clientHeight > event.offsetY) {
            if (this.pageComponents[i].type === 0) break

            if (
              !this.pageComponents[i + 1] ||
              this.pageComponents[i + 1].type === 0
            )
              break

            this.pageComponents = this.pageComponents.filter(
              (res) => res.component !== 'placementarea'
            )

            this.pageComponents.splice(i, 0, {
              component: 'placementarea',
              type: 0,
            })

            break
          }
        }
      } else {
        /* 一个组件都没有直接push */
        this.pageComponents.push({
          component: 'placementarea',
          type: 0,
        })
      }
    },

    /**
     * 当在有效放置目标上放置元素或选择文本时触发此事件
     *
     * @param {Object} event event对象
     */
    drop(event) {
      /* 获取数据 */
      let data = utils.deepClone(
        componentProperties.get(event.dataTransfer.getData('componentName'))
      )

      /* 查询是否只能存在一个的组件且在第一个 */
      let checkComponent = () => {
        let placementarea = false;
        let addCom = false;
        let onlyOne = false;
        let isPage = false;
        this.pageComponents.forEach((item, index) => {
          if (
            item.component === 'placementarea' &&
            index === 0 &&
            this.onlyOne.includes(data.component)
          ) {
            placementarea = true;
          }
          if (!this.onlyOne.includes(data.component)) {
            if (item.setStyle && item.setStyle.isPage) {//有分页的时候
              isPage = true;
            } else if (isPage) {//有分页 并且 没导航和悬浮
              addCom = true;
            }
          }
          if (this.onlyOne.includes(item.component) &&
            item.component === event.dataTransfer.getData('componentName')) {
            onlyOne = true;
          }
        })
        return {
          placementarea,
          addCom,
          onlyOne
        }
      }
      let checkCom = checkComponent()
      if (checkCom.placementarea) {
        this.$message.info('固定位置的组件(如: 底部导航、悬浮)不能放在第一个!')
        /* 删除提示组件 */
        this.dragleaves()
        return
      }
      /* 查询是否只能存在一个的组件 */
      if (checkCom.onlyOne) {
        this.$message.info('当前组件只能添加一个!')
        /* 删除提示组件 */
        this.dragleaves()
        return
      }
      if (checkCom.addCom) {
        this.$message.info('不能在开启拖动分页的组件后面添加组件!')
        /* 删除提示组件 */
        this.dragleaves()
        return
      }

      /* 替换 */
      utils.forEach(this.pageComponents, (res, index) => {
        /* 修改选中 */
        if (res.active === true) res.active = false
        /* 替换提示 */
        this.index = index
        if (res.component === 'placementarea')
          this.$set(this.pageComponents, index, data)
      })

      /* 切换组件 */
      this.rightcom = "";//decorate
      let timeFun = setTimeout(() => {
        this.rightcom = data.style;
        clearTimeout(timeFun);
      }, 300)
      /* 丢样式 */
      this.currentproperties = data.setStyle
    },

    /**
     * 当拖动的元素或文本选择离开有效的放置目标时，会触发此事件
     *
     * @param {Object} event event对象
     */
    dragleaves() {
      /* 删除提示组件 */
      this.pageComponents = this.pageComponents.filter(
        (res) => res.component !== 'placementarea'
      )
    },

    /**
     * 切换组件位置
     *
     * @param {Object} res 组件切换后返回的位置
     */
    componenmanagement(res) {
      this.pageComponents = res
    },

    /**
     * 选择组件
     *
     * @param {Object} res 当前组件对象
     */
    activeComponent(res, index) {
      this.index = index
      /* 切换组件 */
      this.rightcom = res.style
      /* 丢样式 */
      this.currentproperties = res.setStyle

      /* 替换 */
      utils.forEach(this.pageComponents, (res) => {
        /* 修改选中 */
        if (res.active === true) res.active = false
      })

      /* 选中样式 */
      res.active = true
    },

    /**
     * 标题切换
     *
     * @param {Object} res 当前组件对象
     */
    headTop() {
      this.setRightcom();

      /* 替换 */
      utils.forEach(this.pageComponents, (res) => {
        /* 修改选中 */
        if (res.active === true) res.active = false
      })
    },

    /**@method 删除组件 */
    deleteObj(index) {
      this.pageComponents.splice(index, 1)
      if (this.index === index) {
        this.setRightcom();
      }
      if (index < this.index) this.index = this.index - 1
    },

    /**@method 重置数据 */
    reloads() {
      this.$confirm('重置后您添加或者修改的内容将会失效, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          if (this.titleType === 0) {
            this.homePageSetup = {
              // 页面设置属性
              name: '自动获取社团名称', //页面名称
              details: '', //页面描述
              isAddress: true, // 是否显示选择地区
              bgColor: 'rgba(249, 249, 249, 10)', //背景颜色
              bgImg: '', // 背景图片
              titleHeight: 45, // 高度
              titleColor: "#fff",
              titleBgColor: "#3064ba",//标题背景图
              childTitleBgColor: "#5785e7",
              childTitleColor: false,
              titleBgImg: "",
              navigationData: this.homePageSetup.navigationData
            };
          } else if (this.titleType === 1) {
            this.myPageSetup = {
              // 页面设置属性
              name: '会员中心', //页面名称
              details: '', //页面描述
              bgColor: 'rgba(249, 249, 249, 10)', //背景颜色
              bgImg: '', // 背景图片
              titleHeight: 45, // 高度
              titleColor: "#fff",
              titleBgColor: "#3064ba",//标题背景图
              titleBgImg: this.$store.state.ossUrl + "yimai_photos/iconFont/yqhshetuan/center01.png",
            };
          } else if (this.titleType === 2) {
            this.mallPageSetup = {
              // 页面设置属性
              name: '区域商城', //页面名称
              details: '', //页面描述
              bgColor: '#fff', //背景颜色
              bgImg: '', // 背景图片
              titleHeight: 45, // 高度
              titleColor: "#000",
              titleBgColor: "#fff",//标题背景图
              titleBgImg: "",
              showButton: true,
            };
          }
          this.pageComponents = []; //页面组件
          this.setRightcom();
          if (this.titleType === 0) {
            this.currentproperties = this.homePageSetup
          } else if (this.titleType === 1) {
            this.currentproperties = this.myPageSetup
          } else if (this.titleType === 2) {
            this.currentproperties = this.mallPageSetup
          }
        })
        .catch((err) => {
         //-console.log(err);
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.pointer-events {
  pointer-events: none;
}

.home {
  width: 100%;
  height: 100%;

  /* 删除组件 */
  .deles {
    position: absolute;
    min-width: 80px;
    text-align: center;
    line-height: 25px;
    background: #fff;
    height: 25px;
    font-size: 12px;
    left: 106%;
    top: 50%;
    transform: translateY(-50%);

    .icon-sanjiaoxingzuo {
      position: absolute;
      left: -11px;
      color: #fff;
      font-size: 12px;
      top: 50%;
      transform: translateY(-50%);
    }

    &:hover {
      i {
        display: block;
        position: absolute;
        left: 0;
        font-size: 16px;
        top: 0;
        text-align: center;
        line-height: 25px;
        width: 100%;
        color: #fff;
        height: 100%;
        z-index: 10;
        background: rgba(0, 0, 0, 0.5);
      }

      .icon-sanjiaoxingzuo {
        color: rgba(0, 0, 0, 0.5);
      }
    }

    i {
      display: none;
    }
  }

  /* 按钮集合 */
  .buttons {
    height: 8%;
    border-bottom: 1px solid #ebedf0;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding-right: 15px;
    align-items: center;

    /* 下拉 */
    .frop {
      padding-right: 15px;

      .el-button.el-button--primary.el-dropdown-selfdefine {
        background: #fff;
        color: #000;
        border: 1px solid #dcdee0;
      }
    }

    .el-button {
      font-size: 14px;
      padding: 0 16px;
      height: 30px;

      &.el-button--primary {
        background: #51CDCB;
      }

      &.el-button--danger {
        background: red;
      }
    }
  }

  /* 操作主体 */
  .operation {
    width: 100%;
    height: 92%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #f7f8fa;
  }

  /* 手机 */
  .phone {
    width: 55%;
    height: 100%;
    overflow-y: scroll;
    display: flex;
    justify-content: center;
    background: #f7f8fa;
    margin-bottom: 50px;

    &::-webkit-scrollbar {
      width: 3px;
    }

    // &::-webkit-scrollbar-thumb {
    //   background-color: #51CDCB;
    // }

    /* 手机样式 */
    .phoneAll {
      width: 395px;
      min-height: 760px;
      box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.1);
      margin: 45px 0;
      position: relative;

      /* 手机高度 */
      .phoneSize {
        position: absolute;
        left: -137px;
        top: 640px;
        font-size: 12px;
        color: #a2a2a2;
        border-bottom: 1px solid #dedede;
        width: 130px;
        height: 21px;
        line-height: 21px;
      }

      /* 状态栏 */
      .statusBar {
        width: 100%;
        display: block;
      }

      /* 主体内容 */
      .phone-container {
        padding-bottom: 50px;
        min-height: 575px;
        box-sizing: border-box;
        cursor: pointer;
        width: 100%;
        position: relative;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.1);

        .phone-section {
          position: relative;
          z-index: 2;
          min-height: 575px;
        }

        .componentsClass {
          border: 2px solid transparent;

          &:hover {
            border: 2px dashed rgba(81, 205, 203, 0.5);
          }
        }
      }
    }
  }

  /* 右侧工具栏 */
  .decorateAll {
    width: 376px;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    position: relative;
    padding: 0 12px;
    background: #fff;

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #51CDCB;
    }
  }

  /* 页面设置tab */
  .decorateTab {
    position: fixed;
    display: flex;
    right: 380px;
    top: 115px;
    flex-direction: column;
    z-index: 999;

    span {
      background-color: #fff;
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
      border-radius: 2px;
      width: 94px;
      height: 32px;
      display: inline-block;
      text-align: center;
      line-height: 32px;
      margin-bottom: 12px;
      transition: all 0.8s;
      cursor: pointer;

      &.active {
        background-color: #51CDCB;
        color: #fff;
      }

      /* 图标 */
      i {
        font-size: 12px;
        margin-right: 5px;
      }
    }
  }
}

/* 动画 */
.decorateAnima-enter-active {
  transition: all 1.5s ease;
}

.decorateAnima-leave-active {
  transition: all 1.5s ease;
}

.decorateAnima-enter {
  transform: translate(8px, 8px);
  opacity: 0;
}

.decorateAnima-leave-to {
  transform: translate(8px, 8px);
  opacity: 0;
}
</style>
